import debounce from 'lodash/debounce';

import { utils } from '../components/_utils';

const singleRecrutement = {
    topContent : null,
    topSummaryFixed: 70,
    summary : null,
    bottomContent : 0,
    curScrollTop : 0,
    exceedValue: 0,


    loadFn() {
        let self = this;
        self.summary = document.getElementById('sidebar');

        // POSITION FIXED DU WIDGET AU SCROLL SI ON EST PAS SUR MOBILE
        if(utils.getDeviceKind() == 'isDesktop' && self.summary != null) {
            self.topContent = self.getTopContent();
            self.bottomContent = self.getBottomContent();           

            self.setSummaryWidth();
            self.setSummaryPos();

            $(window).on('scroll', () => {
                self.setSummaryPos();
            });

            jQuery(window).on('resize', debounce(function() {
                if(utils.getDeviceKind() == 'isDesktop') {
                    
                    self.topContent = self.getTopContent();
                    self.bottomContent = self.getBottomContent();
                    self.setSummaryPos();
                    self.setSummaryWidth();                    
                    
                } else {
                    
                    self.resetSummary();

                }
            }, 100));
        }
    },

    getTopContent() {
        return $('#single-content').offset().top;
    },

    getBottomContent() {
        return this.getTopContent() + $('#single-content').outerHeight();
    },

    setSummaryPos() {
        let self = this;
        self.curScrollTop = $(window).scrollTop();
        self.exceedValue = self.bottomContent - self.getAbsoluteHeight(self.summary) - self.topSummaryFixed;        

        // si le scrollTop dépasse le Top du content
        if(self.curScrollTop >= (self.topContent - self.topSummaryFixed)) {
            self.summary.classList.add('fixed');
        } else {
            self.summary.classList.remove('fixed');
        }

        // si le scrollTop dépasse le bas du content
        if(self.curScrollTop >= self.exceedValue && utils.hasClass(self.summary, 'fixed')) {
            let translate = self.exceedValue - self.curScrollTop;
            self.summary.style.transform = 'translateY(' + translate + 'px)';
        }
    },

    getAbsoluteHeight : function(el) {
        // Get the DOM Node if you pass in a string
        el = (typeof el === 'string') ? document.querySelector(el) : el; 
      
        var styles = window.getComputedStyle(el);
        var margin = parseFloat(styles['marginTop']) +
                     parseFloat(styles['marginBottom']);
      
        return Math.ceil(el.offsetHeight + margin);
    },

    setSummaryWidth() {
        let self= this;
        let summaryParent = self.summary.parentNode;
        let computedStyle = getComputedStyle(summaryParent);
        self.summary.style.width = (summaryParent.clientWidth - (parseFloat(computedStyle.paddingLeft) + parseFloat(computedStyle.paddingRight))) + 'px';
        self.summary.style.top = self.topSummaryFixed + 'px';
    },

    resetSummary() {
        let self= this;

        self.summary.removeAttribute('style');
        self.summary.classList.remove('fixed');
    }
}

export { singleRecrutement };